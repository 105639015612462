import {Directive, ElementRef} from '@angular/core';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';

@Directive({
  selector: '[xngCustomization]'
})
export class XngCustomizationDirective {

  constructor(
    private translate: TranslateService,
    private el: ElementRef
  ) { }

  ngAfterViewInit() {
    this.translate.get('guide.choiceAll.youAreHere').subscribe((res: string) => {
      this.el.nativeElement.children.item(0).setAttribute('aria-label', res);
    });
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.translate.get('guide.choiceAll.youAreHere').subscribe((res: string) => {
        this.el.nativeElement.children.item(0).setAttribute('aria-label', res);
      });
    });

    for (let item of this.el.nativeElement.children.item(0).getElementsByClassName('xng-breadcrumb-link')) {
      item.removeAttribute('role');
    }
  }

}
